import React, { useState, useCallback, useEffect } from 'react';
import { Box, Grid, Slider } from '@mui/material';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import './editActions.css'; // Import the CSS file
import useActionTemplates from '../../utils/useActionTemplates';
import useIsMobile from '../../utils/useIsMobile';
import useCopyArray from '../../utils/useCustomVariableCopyArray';
import useSystemVariables from '../../utils/useSystemVariables';
import { useUserSettings } from '../../store/usersettings-context';
import CustomSnackbar from '../../utils/customsnackbar';
import VariableCopyDropdown from '../../components/commands/VariableCopyDropdown';
import StringBuilder from '../../components/stringbuilder/StringBuilder';
import { Sidebar as FullscreenSidebar } from 'primereact/sidebar';

const EditActions = ({actionsArr = [], save = (newActionsArr = []) => {}, backgroundColor = '#5D3B9E', textColor = '#e0e0e0', excludeArr = []}) => {
    const { userSettings } = useUserSettings();
    const [actionTemplates] = useActionTemplates();
    const [systemVariables] = useSystemVariables();
    const [snackAlert, setSnackAlert] = useState({open: false, severity: 'success', text: '', autoHideDuration: 5000});
    const [visibleRight, setVisibleRight] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [localActionsArr, setLocalActionsArr] = useState(actionsArr);
    const [showVariableHelper, setShowVariableHelper] = useState(false);
    const isMobile = useIsMobile();
    const { getCopyArray } = useCopyArray();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`,
    };
    const sliderStyle = {
        '& .MuiSlider-thumb': { backgroundColor, opacity: 1 },
        '& .MuiSlider-rail': { backgroundColor, opacity: 1 },
        '& .MuiSlider-track': { backgroundColor, opacity: 1, border: 'none' },
    };
    const placeholderTextMap = {
        standard: 'Message',
        enhanced: 'Message',
        variable: 'Command',
        tts: 'Message',
    };
    const operatorsOptions = {
        Equals: '=',
        'Is less than': '<',
        'Is greater than': '>',
        'Does not equal': '!',
        'Either true': '|',
        Contains: '&',
        'Not contains': '^',
    };

    useEffect(() => {
        save(localActionsArr);
    }, [localActionsArr]);

    useEffect(() => {
        setLocalActionsArr(actionsArr);
    }, [actionsArr]);

    useEffect(() => {
        if (localActionsArr.length === 0) {
            setSelectedIndex(null);
        } else if (selectedIndex >= localActionsArr.length) {
            setSelectedIndex(localActionsArr.length - 1);
        }
    }, [localActionsArr, selectedIndex]);      

    const getAutocompleteData = useCallback(() => {
        if ('customVariables' in userSettings) {
            const _returnArr = [];
            systemVariables.forEach((variable) => {
                _returnArr.push(variable.copy);
            });
            Object.keys(userSettings['customVariables']).forEach((variableKey) => {
                const variable = userSettings['customVariables'][variableKey];
                const copyArray = getCopyArray(variableKey, variable['datatype'], variable['unique'] ?? false);
                _returnArr.push(...copyArray);
            });
            return _returnArr;
        } else {
            return [];
        }
    }, [userSettings, systemVariables, getCopyArray]);

    const moveElement = useCallback((array, index, direction) => {
            const newArray = [...array];
            const newIndex = direction === 'up' ? index - 1 : index + 1;
            if (newIndex < 0 || newIndex >= array.length) return array;
            [newArray[index], newArray[newIndex]] = [newArray[newIndex], newArray[index]];
            return newArray;
    }, []);

    const addAction = useCallback(() => {
        setLocalActionsArr((prev) => [...prev, {type: 'Standard', message: ''}]);
    }, []);

    const capitalizeFirstLetter = useCallback((string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);

    const MyInputText = ({ text = '', save, placeholderText = 'Message' }) => {
        const [txtBuffer, setTxtBuffer] = useState(text);

        useEffect(() => {
            setTxtBuffer(text);
        }, [text]);

        return (
            <InputText style={backgroundColor ? { width: '100%', height: '48px', backgroundColor } : { width: '100%', height: '48px' }}
                placeholder={placeholderText} value={txtBuffer} onChange={(e) => setTxtBuffer(e.target.value)} onBlur={() => save(txtBuffer)} />
        );
    };

    const MyInputNumber = ({value = 0, save, min = 0, max = 100, step = 1, placeholderText = ''}) => {
        const [numberValue, setNumberValue] = useState(value);
      
        useEffect(() => {
          setNumberValue(value);
        }, [value]);
      
        return (
            <InputNumber value={numberValue} onChange={(e) => setNumberValue(e.value)} onBlur={() => save(numberValue)} min={min} max={max} step={step}
                showButtons buttonLayout='horizontal' style={backgroundColor ? { width: '100%', backgroundColor } : { width: '100%' }} placeholder={placeholderText} />
        );
    };

    const MyAutoComplete = ({text = '', save, placeholderText = 'Command'}) => {
        const [txtBuffer, setTxtBuffer] = useState(text);
        const [filteredSuggestions, setFilteredSuggestions] = useState([]);

        useEffect(() => {
        setTxtBuffer(text);
        }, [text]);

        const searchSuggestions = (event) => {
            const query = event.query;
            const results = getAutocompleteData().filter((item) =>
                item.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSuggestions(results);
        };

        const handleBlur = (e) => {
            setTimeout(() => {
                save(e.target.value);
            }, 250);
        };

        return (
        <AutoComplete style={{width: '100%', height: '48px', backgroundColor, borderTopRightRadius: '6px', borderBottomRightRadius: '6px'}} placeholder={placeholderText} value={txtBuffer} suggestions={filteredSuggestions}
            completeMethod={searchSuggestions} onChange={(e) => setTxtBuffer(e.value)} onSelect={(e) => setTxtBuffer(e.value)} onBlur={handleBlur} dropdown />
        );
    };

    const ActionTypeInput = ({actionType, actionObj, onChange, index, isSubAction = false}) => {
        switch (actionType.toLowerCase()) {
        case 'standard':
        case 'enhanced':
        case 'tts standard':
        case 'tts enhanced':
            return (
                <MyInputText placeholderText={placeholderTextMap[actionType.toLowerCase()]} text={actionObj['message']} save={(newMessage) => {
                    onChange({ ...actionObj, message: newMessage });
                }} />
            );
        case 'variable':
            return (
                <MyAutoComplete placeholderText={placeholderTextMap[actionType.toLowerCase()]} text={actionObj['message']} save={(newMessage) => {
                    onChange({ ...actionObj, message: newMessage });
                    }}
                />
            );
        case 'delay':
            return (
                <Slider sx={{ ...sliderStyle, marginTop: '8px' }} value={actionObj['seconds']} min={1} max={60} step={1} valueLabelDisplay="auto"
                    valueLabelFormat={actionObj['seconds'].toString()} onChange={(e, v) => {
                    onChange({ ...actionObj, seconds: v });
                    }}
                />
            );
        case 'compare':
            return <CompareActionInput actionObj={actionObj} onChange={onChange} index={index} isSubAction={isSubAction} />;
        case 'macro':
            return (
                <Dropdown style={{width: '100%', backgroundColor}} value={actionObj['macro']} placeholder="Select a macro" 
                    options={Object.keys(userSettings['macros']).map((m) => ({label: m, value: m}))} onChange={(e) => onChange({ ...actionObj, macro: e.value })} />
            );
        case 'spotify':
            const spotifyActions = ['Skip To Next', 'Add To Queue', 'Pause Playback', 'Start / Resume Playback', 'Set Volume'].sort();
            const spotifyRequiresVariable = (type = '') => {
                switch (type) {
                    case 'Add To Queue':
                    case 'Set Volume':
                    case 'Shuffle':
                    case 'Repeat Mode':
                        return true;
                
                    default:
                        return false;
                }
            };
            return (
                <Grid container spacing={2}>
                    <Grid item xs={spotifyRequiresVariable(actionObj?.action || '') ? 4 : 12}>
                        <Dropdown style={{width: '100%', backgroundColor}} value={actionObj['action']} placeholder='Select an action'
                            options={spotifyActions.map((m) => ({label: m, value: m}))} 
                            onChange={(e) => onChange({...actionObj, action: e.value, message: spotifyRequiresVariable(e.value) ? '' : 'N/A'})} />
                    </Grid>
                    {spotifyRequiresVariable(actionObj?.action || '') && (
                        <Grid item xs={8}>
                            {actionObj['action'].toLowerCase() === 'add to queue' && (
                                <MyInputText text={actionObj['message']} placeholderText='Spotify URL, URI or search text' save={(newMessage) => {
                                    onChange({...actionObj, message: newMessage});
                                }} />
                            )}
                            {actionObj['action'].toLowerCase() === 'set volume' && (
                                <MyInputText text={actionObj['message']} placeholderText='50' save={(newNumber) => {
                                    onChange({...actionObj, message: newNumber})
                                }} />
                            )}
                        </Grid>
                    )}
                </Grid>
            );
        case 'twitch':
            const twitchActions = ['Start Commercial', 'Snooze Next Ad', 'Update Title', 'Update Category', 'Create Announcement', 'Create Shoutout', 'Create Clip'].sort();
            const actionPlaceholders = {'Start Commercial': 'Desired length of ad in seconds (max 180)', 'Update Title': 'New title for stream',
                'Update Category': 'New game/stream category', 'Create Announcement': 'Announcement message',
                'Create Shoutout': 'User to give shoutout to'};
            const twitchRequiresVariable = (type = '') => actionPlaceholders.hasOwnProperty(type);
            
            return (
                <Grid container spacing={2}>
                    <Grid item xs={twitchRequiresVariable(actionObj?.action || '') ? 4 : 12}>
                        <Dropdown style={{ width: '100%', backgroundColor }} value={actionObj['action']} placeholder="Select an action"
                        options={twitchActions.map((m) => ({ label: m, value: m }))} onChange={(e) =>
                            onChange({...actionObj, action: e.value, message: twitchRequiresVariable(e.value) ? '' : 'N/A'})} />
                    </Grid>
                    {twitchRequiresVariable(actionObj?.action || '') && (
                        <Grid item xs={8}>
                            <MyInputText text={actionObj['message']} placeholderText={actionPlaceholders[actionObj['action']]} 
                                save={(newMessage) => onChange({ ...actionObj, message: newMessage })} />
                        </Grid>
                    )}
                </Grid>
            );
        default:
            return null;
        }
    };

    const CompareActionInput = ({actionObj, onChange, index, isSubAction = false}) => {
        return (
        <Grid container spacing={isMobile && 1} sx={{ width: '100%' }}>
            <Grid item xs={isMobile ? 12 : 4}>
            <div style={{ maxWidth: !isMobile && '90%' }}>
                <MyAutoComplete text={actionObj['value1']} save={(newMessage) => onChange({ ...actionObj, value1: newMessage })} placeholderText="" />
            </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
            <Dropdown style={{ backgroundColor, width: isMobile ? '100%' : '90%' }} options={Object.keys(operatorsOptions).map((o) => ({label: o, value: operatorsOptions[o]}))}
                value={actionObj['operator']} onChange={(e) => onChange({ ...actionObj, operator: e.value })} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
            <div style={{ maxWidth: !isMobile && '90%' }}>
                <MyAutoComplete text={actionObj['value2']} save={(newMessage) => onChange({ ...actionObj, value2: newMessage })} placeholderText="" />
            </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 2}>
            <Button style={{width: '100%'}} label="Then" onClick={() => {
                    setVisibleRight(true);
                    setSelectedIndex(index);
                    if (!actionObj['actions']) {
                        onChange({ ...actionObj, actions: [] });
                    }
                }} />
            </Grid>
        </Grid>
        );
    };

    const ActionItem = ({actionObj, index, onMoveUp, onMoveDown, onDelete, onChange, isSubAction = false}) => {
        const actionType = actionObj['type'];
        const styles = {
            sidebarItemStyle: {
                backgroundColor: 'rgba(0, 0, 0, 0.25)', 
                padding: 16,
                margin: 8,
                width: '100%',
                borderRadius: '8px',
                border: '2px solid rgba(0, 0, 0, 0.25)'
            }
        };

        return (
            <Grid item xs={12} key={index} style={isSubAction ? {height: '100%', marginTop: isMobile ? '0px' : '32px', marginLeft: '40px'} : {}}>
                <Grid container spacing={isMobile ? 0 : 2} sx={{height: '100%'}} alignContent="center">
                    {isSubAction ? (
                        <React.Fragment>
                            <Grid item xs={12} style={styles.sidebarItemStyle}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Dropdown value={capitalizeFirstLetter(actionType)} placeholder="Select an Action" style={{width: '100%', backgroundColor}}
                                            options={Object.keys(actionTemplates)
                                                .filter((a) => (isSubAction ? a !== 'Compare' : true))
                                                .filter((a) => !excludeArr.includes(a.toLowerCase()))
                                                .map((a) => ({ label: a, value: a }))
                                                .sort((a, b) => a.label.localeCompare(b.label))}
                                            onChange={(e) => {
                                                const newActionObj = { ...actionObj, type: e.value };
                                                if (e.value.toLowerCase() === 'delay') {
                                                    newActionObj['message'] = '';
                                                    newActionObj['seconds'] = actionTemplates['Delay']['seconds'];
                                                }
                                                onChange(newActionObj);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ActionTypeInput actionType={actionType} actionObj={actionObj} onChange={onChange} index={index} isSubAction={isSubAction} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container sx={{marginTop: '4px'}}>
                                            <Grid item xs={2}>
                                                <Button id='move-subaction-up' onClick={onMoveUp} size='small' icon='pi pi-arrow-up' severity='info' />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button id='move-subaction-down' onClick={onMoveDown} size='small' icon='pi pi-arrow-down' severity='info' />
                                            </Grid>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button size='small' style={{ width: '100%' }} severity='danger' label='Delete' onClick={onDelete} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <Grid container spacing={1} sx={{marginTop: '8px', marginBottom: '8px'}}>
                            <Grid item xs={isMobile ? 4 : 1}>
                                <Box display="flex" alignItems="center">
                                    <Grid container sx={{ width: '40px' }}>
                                        <Grid item xs={12}>
                                            <Button style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}} text icon='pi pi-arrow-up' size='small' onClick={onMoveUp} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}} text icon='pi pi-arrow-down' size='small' onClick={onMoveDown} />
                                        </Grid>
                                    </Grid>
                                    <h2 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>{`#${index + 1}`}</h2>
                                </Box>
                            </Grid>
                            <Grid item xs={isMobile ? 8 : isSubAction ? 12 : 2}>
                                <Dropdown value={capitalizeFirstLetter(actionType)} placeholder="Select an Action" style={{width: '100%', backgroundColor}}
                                    options={Object.keys(actionTemplates)
                                        .filter((a) => (isSubAction ? a !== 'Compare' : true))
                                        .filter((a) => (isSubAction || a !== 'Break'))
                                        .filter((a) => !excludeArr.includes(a.toLowerCase()))
                                        .map((a) => ({ label: a, value: a }))
                                        .sort((a, b) => a.label.localeCompare(b.label))}
                                    onChange={(e) => {
                                        const newActionObj = { ...actionObj, type: e.value };
                                        if (e.value.toLowerCase() === 'delay') {
                                            newActionObj['message'] = '';
                                            newActionObj['seconds'] = actionTemplates['Delay']['seconds'];
                                        }
                                        onChange(newActionObj);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 8} sx={{ marginTop: isMobile && '16px' }}>
                                <ActionTypeInput actionType={actionType} actionObj={actionObj} onChange={onChange} index={index} isSubAction={isSubAction} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 1} sx={{ marginTop: isMobile && '8px', paddingRight: isMobile && '8px' }}>
                                <Button style={{ height: '48px', width: '100%' }} size="small" icon="pi pi-times" onClick={onDelete}/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {isMobile && <Divider />}
            </Grid>
        );
    };

    const ActionList = ({actionsArr = [], setActionsArr = () => {}, isSubAction = false}) => {
        return (
            <Grid container style={{overflowY: isMobile ? 'hidden' : 'auto', height: '100%', paddingTop: isMobile && '40px'}} spacing={isMobile ? 1 : 0}>
                {actionsArr.map((actionObj, index) => (
                    <ActionItem key={index} actionObj={actionObj} index={index}
                        onMoveUp={() =>
                            setActionsArr((prev) => moveElement(prev, index, 'up'))
                        }
                        onMoveDown={() =>
                            setActionsArr((prev) => moveElement(prev, index, 'down'))
                        }
                        onDelete={() =>
                            setActionsArr((prev) => prev.filter((_, i) => i !== index))
                        }
                        onChange={(newActionObj) => {
                            setActionsArr((prev) => {
                                const actions = [...prev];
                                actions[index] = newActionObj;
                                return actions;
                            });
                        }}
                        isSubAction={isSubAction}
                    />
                ))}
            </Grid>
        );
    };

    const MySidebar = React.memo(() => {
        const selectedAction = selectedIndex !== null ? localActionsArr[selectedIndex] : null;
        const [actions, setActions] = useState(selectedAction ? selectedAction['actions'] || [] : []);

        const onHide = () => {
            setLocalActionsArr((prev) => {
                const localActionsArrCopy = [...prev];
                localActionsArrCopy[selectedIndex]['actions'] = [...actions];
                return localActionsArrCopy;
            });
            setVisibleRight(false);
        };

        return (
            <Sidebar style={{width: '35vw', minWidth: isMobile ? '100%' : '500px', backgroundColor, '--complementary-color': textColor}} 
                visible={visibleRight} position="right" onHide={onHide}>
                <Grid container spacing={4}>
                    <Grid item xs={12} style={{marginBottom: 16, marginTop: 8}}>
                        <Grid container spacing={2}>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Button label="Add Action" icon="pi pi-plus" severity='success' style={{width: '100%'}}
                                    onClick={() => {
                                        setActions((prev) => {
                                            const actionsCopy = [...prev];
                                            actionsCopy.push({type: 'Standard', message: ''});
                                            return actionsCopy;
                                        });
                                    }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <div style={{float: 'right', width: isMobile && '100%'}}>
                                    <VariableCopyDropdown userSettings={userSettings} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {selectedAction && (
                        <ActionList actionsArr={actions} setActionsArr={(newActionsArr) => setActions(newActionsArr)} isSubAction={true} />
                    )}
                </Grid>
            </Sidebar>
        );
    });

    return (
        <React.Fragment>
            <CustomSnackbar open={snackAlert.open} autoHideDuration={snackAlert.autoHideDuration} severity={snackAlert.severity}
                snackText={snackAlert.text} setSnackAlert={setSnackAlert} />
                <div className="card flex justify-content-center">
                    <FullscreenSidebar visible={showVariableHelper} onHide={() => setShowVariableHelper(false)} fullScreen>
                        <StringBuilder backgroundColor={backgroundColor} userSettings={userSettings} />
                    </FullscreenSidebar>
                </div>
            <Grid container sx={{marginBottom: '16px'}} spacing={3}>
                <Grid item xs={12}>
                    <h1 style={{margin: '0px', padding: '0px', color: 'var(--complementary-color)'}}>Actions</h1>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                    <Button onClick={addAction} size={'small'} label='Add Action' icon='pi pi-plus' iconPos='left'
                        style={{width: '100%'}} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 3}>
                    <Button label='Variable Helper' size={'small'} onClick={() => setShowVariableHelper(true)}
                        style={{width: '100%'}} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <div style={{ float: 'right', width: isMobile && '100%' }}>
                        <VariableCopyDropdown userSettings={userSettings} setSnackAlert={setSnackAlert} backgroundColor={backgroundColor} />
                    </div>
                </Grid>
            </Grid>
            {isMobile && <Divider />}
            {localActionsArr.length > 0 &&
                localActionsArr.map((actionObj, index) => (
                    <ActionItem key={index} actionObj={actionObj} index={index} isSubAction={false}
                        onMoveUp={() =>
                            setLocalActionsArr((prev) => moveElement(prev, index, 'up'))
                        }
                        onMoveDown={() =>
                            setLocalActionsArr((prev) => moveElement(prev, index, 'down'))
                        }
                        onDelete={() =>
                            setLocalActionsArr((prev) => prev.filter((_, i) => i !== index))
                        }
                        onChange={(newActionObj) => {
                            setLocalActionsArr((prev) => {
                                const actions = [...prev];
                                actions[index] = newActionObj;
                                return actions;
                            });
                        }}
                    />
                ))
            }
            <MySidebar />
        </React.Fragment>
    );
};

export default EditActions;