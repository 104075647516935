import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TrackTable from './TrackTable';
import { useUser } from '../../context/UserContext';

const RequestQueue = ({backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, openDialogComponent = () => {}, setIsBlur = () => {}, refresh = () => {}}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useUser();
    const [queue, setQueue] = useState([]);
    const [trackRequests, setTrackRequests] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const styles = {
        container: {
            backgroundColor,
            width: '100%',
            height: '70vh',
            paddingLeft: '32px',
            paddingRight: '32px',
            paddingTop: '32px'
        },
        noMarginOrPadding: {
            margin: '0px',
            padding: '0px'
        },
        title: {
            color: 'var(--complementary-color)'
        }
    };

    const transformData = (items = [], trackRequests = {}) => {
        const msToMinutesAndSeconds = (ms) => {
            const minutes = Math.floor(ms / 60000);
            const seconds = ((ms % 60000) / 1000).toFixed(0);
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        };
        if (items.length > 0) {
            let _tableData;
            _tableData = items.map((item, index) => ({
                id: index + 1,
                trackName: item['name'],
                artists: item['artists'],
                albumName: item['album']['name'],
                albumUri: item['album']['uri'],
                image: item['album']['images'][0]['url'],
                duration: msToMinutesAndSeconds(item['duration_ms']),
                uri: item['uri'],
                songRequester: Object.keys(trackRequests).includes(item['id']) ? trackRequests[item['id']] : ''
            }));
            return _tableData;
        }
    };
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${apiUrl}/user/queueandrequests?token=${authCtx.token}`);
            const data = await response.json();
            if (response.ok) {
                setQueue(data['queue']);
                setTrackRequests(data['trackRequests']);
                setLoading(false);
            } else {
                setError(data['error']);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        setInterval(fetchData, 5000);
    }, []);

    return (
        <Box sx={styles.container}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TrackTable tableData={transformData(queue, trackRequests)} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default RequestQueue;